/*
    Modifications copyright 2018 The caver-js Authors
    This file is part of web3.js.

    web3.js is free software: you can redistribute it and/or modify
    it under the terms of the GNU Lesser General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    web3.js is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU Lesser General Public License for more details.

    You should have received a copy of the GNU Lesser General Public License
    along with web3.js.  If not, see <http://www.gnu.org/licenses/>.

    This file is derived from web3.js/packages/web3-core-requestmanager/src/jsonrpc.js (2019/06/12).
    Modified and improved for the caver-js development.
*/
/** @file jsonrpc.js
 * @authors:
 *   Fabian Vogelsteller <fabian@ethereum.org>
 *   Marek Kotewicz <marek@ethdev.com>
 *   Aaron Kumavis <aaron@kumavis.me>
 * @date 2015
 */

// Initialize Jsonrpc as a simple object with utility functions.
const Jsonrpc = {
    messageId: 0,
}

/**
 * Should be called to valid json create payload object
 *
 * @method toPayload
 * @param {Function} method of jsonrpc call, required
 * @param {Array} params, an array of method params, optional
 * @returns {Object} valid jsonrpc payload object
 */
Jsonrpc.toPayload = function(method, params) {
    if (!method) {
        throw new Error(`JSONRPC method should be specified for params: "${JSON.stringify(params)}"!`)
    }

    Jsonrpc.messageId++

    return {
        jsonrpc: '2.0',
        id: Jsonrpc.messageId,
        method: method,
        params: params || [],
    }
}

Jsonrpc.isValidResponse = function(response) {
    return Array.isArray(response) ? response.every(validateSingleMessage) : validateSingleMessage(response)

    function validateSingleMessage(message) {
        return (
            !!message &&
            !message.error &&
            message.jsonrpc === '2.0' &&
            (typeof message.id === 'number' || typeof message.id === 'string') &&
            message.result !== undefined
        ) // only undefined is not valid json object
    }
}

Jsonrpc.toBatchPayload = function(messages) {
    return messages.map(function(message) {
        return Jsonrpc.toPayload(message.method, message.params)
    })
}

module.exports = Jsonrpc
