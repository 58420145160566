/*
    Copyright 2018 The caver-js Authors
    This file is part of the caver-js library.

    The caver-js library is free software: you can redistribute it and/or modify
    it under the terms of the GNU Lesser General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    The caver-js library is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
    GNU Lesser General Public License for more details.

    You should have received a copy of the GNU Lesser General Public License
    along with the caver-js. If not, see <http://www.gnu.org/licenses/>.
*/

const { rlpEncodeForLegacyTransaction } = require('./legacy')

const {
    rlpEncodeForValueTransfer,
    rlpEncodeForValueTransferMemo,

    rlpEncodeForFeeDelegatedValueTransfer,
    rlpEncodeForFeeDelegatedValueTransferWithRatio,
    rlpEncodeForFeeDelegatedValueTransferMemo,
    rlpEncodeForFeeDelegatedValueTransferMemoWithRatio,
} = require('./valueTransfer')

const {
    rlpEncodeForAccountUpdate,
    rlpEncodeForFeeDelegatedAccountUpdate,
    rlpEncodeForFeeDelegatedAccountUpdateWithRatio,
    parseAccountKey,
} = require('./account')

const {
    rlpEncodeForContractDeploy,
    rlpEncodeForContractExecution,
    rlpEncodeForFeeDelegatedSmartContractDeploy,
    rlpEncodeForFeeDelegatedSmartContractDeployWithRatio,
    rlpEncodeForFeeDelegatedSmartContractExecution,
    rlpEncodeForFeeDelegatedSmartContractExecutionWithRatio,
} = require('./contract')

const { rlpEncodeForCancel, rlpEncodeForFeeDelegatedCancel, rlpEncodeForFeeDelegatedCancelWithRatio } = require('./cancel')

const { rlpEncodeForChainDataAnchoring } = require('./serviceChain')

module.exports = {
    // 1. legacy
    rlpEncodeForLegacyTransaction,
    // 2. value transfer
    rlpEncodeForValueTransfer,
    rlpEncodeForValueTransferMemo,
    rlpEncodeForFeeDelegatedValueTransfer,
    rlpEncodeForFeeDelegatedValueTransferWithRatio,
    rlpEncodeForFeeDelegatedValueTransferMemo,
    rlpEncodeForFeeDelegatedValueTransferMemoWithRatio,
    // 3. account
    rlpEncodeForAccountUpdate,
    rlpEncodeForFeeDelegatedAccountUpdate,
    rlpEncodeForFeeDelegatedAccountUpdateWithRatio,
    parseAccountKey,
    // 4. contract
    rlpEncodeForContractDeploy,
    rlpEncodeForFeeDelegatedSmartContractDeploy,
    rlpEncodeForFeeDelegatedSmartContractDeployWithRatio,
    rlpEncodeForFeeDelegatedSmartContractExecutionWithRatio,

    rlpEncodeForContractExecution,
    rlpEncodeForFeeDelegatedSmartContractExecution,
    // 5. cancel
    rlpEncodeForCancel,
    rlpEncodeForFeeDelegatedCancel,
    rlpEncodeForFeeDelegatedCancelWithRatio,
    // 6. service chain
    rlpEncodeForChainDataAnchoring,
}
